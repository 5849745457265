import React, {useContext} from 'react';
import LanguageContext from "core/context/LanguageContext";
import ReactGA from "react-ga";

export function ScheduleTourButton() {
  const language = useContext(LanguageContext);

  // const ScheduleTourButtonClickHandler = () => {

  //   ReactGA.event({
  //     category: 'ScheduleTourButton',
  //     action: 'ScheduleTour button clicked'
  //   })
  // }

  const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }

  return (
    <div className="container">
        <button className="btn" onClick={eventTrack.bind(this, "ScheduleTourButton", "ScheduleTourButton clicked", "Button")}>{language === 'fr' ? 'ACHETER' : 'Tour Schedule'}</button>
    </div>
  );
}
