import React, { useEffect } from 'react';
import './style.scss';

export function PageNotFound() {
  // Equivalent to componentDidMount
  useEffect(() => {
    document.title = "Page Not Found"
  }, []);

  return (
    <div className="PageNotFound">
      <h1>404</h1>
    </div>
  );
}
