import React, { useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import covid_logo from '../../assets/images/covid.png';
import "./CovidPopUPForm.scss";
import { Grid } from "@material-ui/core";
// import { ContactForm } from '../ContactForm/ContactForm';
import { BuyTicketsButton } from '../BuyTicketsButton/BuyTicketsButton';
import LogoFacebook from "core/assets/SOCIAL_ICONS_WHITE/FB_ICON.png";
import LogoTwitter from "core/assets/SOCIAL_ICONS_WHITE/TWITTER_ICON.png";
import LogoInstagram from "core/assets/SOCIAL_ICONS_WHITE/IG_ICON.png";
import LogoYoutube from "core/assets/SOCIAL_ICONS_WHITE/YOUTUBE_ICON.png";
import LogoSpotify from "core/assets/SOCIAL_ICONS_WHITE/SPOTIFY_ICON.png";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import ReactGA from 'react-ga';



export default function CovidPopUPForm(props) {
    const [open, setOpen] = React.useState(false);

    const [country, setCountry] = React.useState(false);
    const [region, setRegion] = React.useState(false);

    useEffect(() => {

        setTimeout(() => {
            setOpen(true)
        }, 3000)
    }, [])

    const handleClickOpen = () => {
        setOpen(false);
    };

    const selectCountry = (val) => {
        setCountry(val);
      }
    
    const  selectRegion = (val) => {
        setRegion(val);;
      }

    const SubmitButtonClickHandler = () => {

        ReactGA.event({
            category: 'SubmitButton',
            action: 'Submit button clicked'
        })
    }

    return (
        <div className="div_popupCovidForm">
        {open?<div className="popupCovidForm">
                
            <div className="heading_covidPopUPForm">
                <span>Back on the road soon! Subscribe to get the latest announcements.</span>
            </div>
                       
            <IconButton onClick={handleClickOpen}>
                <CloseIcon />
            </IconButton>
           
            <form action="https://monlove.us20.list-manage.com/subscribe/post?u=009e0b50fb55c7ef99503bb5f&id=2433ad2856" method="post">
                                                
                {/* <input type="text"  required placeholder="First Name*" name="FNAME" className="for_form"/>
                <br/>
            
                <input type="text" required placeholder="Last Name*" name="LNAME" className="for_form"/>
                <br/> */}
            
                <input type="email" required placeholder="Email Address*" name="EMAIL" className="for_form"/>
                <br/>
                        
                    <CountryDropdown
                    className="for_form_dp"
                    value={country}
                    onChange={(val) => selectCountry(val)} />
                    <br/>

                    <RegionDropdown
                    className="for_form_dp"
                    country={country}
                    value={region}
                    onChange={(val) => selectRegion(val)} />
                    <br/>
                       
            
                <button 
                    className="signUpBtnCF"
                    type="submit" 
                    onClick={SubmitButtonClickHandler}
                    >
                    SUBMIT
                </button>
                <br/>

                {/* <button 
                    className="signUpBtnCF"
                    type="submit" 
                    component={Link}
                    to="/tickets"
                    >
                    BUY TICKETS
                </button> */}

                <Link to="/tickets">
                    <BuyTicketsButton/>
                </Link>
                
               
                <div style={{fontSize:"15px"}}>
                    <p>FOLLOW US ON SOCIAL MEDIA</p>                       
                </div>
                <div className="for_social_icon">
                    <a href="https://www.facebook.com/ScoobyLiveTour/">
                        <img src={LogoFacebook} alt="Scooby Doo Facebook Icon" />
                    </a>
                             
                    <a href="https://twitter.com/scoobylivetour">
                        <img src={LogoTwitter} alt="Scooby Doo Twitter Icon" />
                    </a>
                             
                    <a href="https://www.instagram.com/scoobylivetour/">
                        <img src={LogoInstagram} alt="Scooby Doo Instagram Icon" />
                    </a>
                
                    <a href="https://www.youtube.com/channel/UCcnAgnT8tFkD41snmvItHag">
                        <img src={LogoYoutube} alt="Scooby Doo Youtube Icon" />
                    </a>
                       
                    <a href="https://open.spotify.com/album/15qgtWmQZnqJe4GjZQgzWS">
                        <img src={LogoSpotify} alt="Scooby Doo Spotify Icon" />
                    </a>          
                </div>                 
            </form>
        

        </div>:null}
        </div>

    );
}


