import React, { useEffect, useContext } from 'react';
import './style.scss';
import { retrieveItemByKey } from 'core/services/ContentService';
import LanguageContext from "core/context/LanguageContext";
import bg_img from '../../assets/images/NEWS_Title.png';
import { Footer } from "core/modules/Footer/Footer";
import {newsArticles} from "../../constant/newsArticles";
// import PDF from '../../../core/assets/documents/ScoobyDoo_KIT.pdf';

export function NewsPage() {
  const currentLanguage = useContext(LanguageContext)
  const [newsData, setNewsData] = React.useState([]);

  useEffect(() => {
    document.title = retrieveItemByKey(currentLanguage, '/newspages/PageTitle')
    var sortedbydate = retrieveItemByKey(null, 'newsarticles').sort(function (a, b) {
      var c = new Date(a.date.substring(0, 10));
      var d = new Date(b.date.substring(0, 10));
      return d - c;
    })
    setNewsData(sortedbydate)
  }, [currentLanguage]);

  return (
    <div className="NewsPage">
      <div className="news-container">
        <div className="hidden"><h1>{retrieveItemByKey(currentLanguage, '/newspages/PageHeader')}</h1></div>
        <div><img className="bg-img-header" src={bg_img} alt={retrieveItemByKey(currentLanguage, '/newspages/PageHeader')} /></div>
        {/* {
          newsData.filter(newsArticle => newsArticle.Language === currentLanguage).map((data, index) => {
            return <div key={index} className="news-content-container">
                    <div className="content">
                      <div className="external-link">
                        <a target="_blank" href={data.external_link.pdf}><div></div></a>
                      </div>
                      <div className="left-content">
                        <div className="first-item">{data.title}</div>
                        <div className="second-item"><img src={data.company_image && data.company_image.url} alt={data.title}/></div>
                        <div className="third-item"><p>{data.date.substring(0,10)}</p></div>
                      </div>
                      <div className="right-content">
                        <div className="snippet">{data.articletext}</div>
                        <div><img src={data.ScoobyDooImage && data.ScoobyDooImage.url} alt={data.title}/></div>
                      </div>
                    </div>
                    <br/>
                  </div>
          })
        } */}

        {
          newsArticles.map((article) => {
            return <div className="news-content-container">
              <div className="content">
                <div className="external-link">
                  <a target="_blank" href={article.pdf} rel="noreferrer"></a>
                </div>
                <div className="left-content">
                  <div className="first-item"><img src={article.newsArticleLogo}  /></div>
                  <div className="second-item">{article.articletext}</div>
                  <div className="third-item"><img src={article.ScoobyDooImage} /></div>
                  <div className="forth-item"><button className='btn' onClick={() => window.open(article.pdf)}>Read Article</button></div>
                </div>
              </div>
              <br />
            </div>
          })
        }
      </div>
      <Footer />
    </div>
  );
}