import React from 'react';
import './style.scss';
import { Grid } from '@material-ui/core';
import { Footer } from "core/modules/Footer/Footer";
import header from "../../assets/compressedimages/PRIVACY_POLICY.png";

export function Legal() {
  return (
    <Grid container className="legalPage">
      <Grid container className="legalText" alignContent="center">
        <Grid item xs={12} >
          {/* <h1 className="privacyTitle">PRIVACY POLICY</h1> */}
          <img src={header} alt="PRIVACY POLICY" className="headerImg"></img>
        </Grid>
        <Grid item className="legalParagraph" xs={12}>
          <p>LAST MODIFIED: February 23, 2021</p><br />
          <p>
          Thank you for visiting monlove.com.  Monlove Creations inc. (which may hereinafter be references as “MONLOVE”, “We”, “Us”, or “Our”) respects the privacy of Our customers and the users of Our website. In this “Privacy Policy”, We want to provide you with explicit information on how We collect, use, share and retain information whenever you purchase tickets to Our shows, telephone or fax Us inquiries, sign up to receive email notifications from or about Us, visit and provide information in a public forum, visit Our website, or apply for a position.           
          </p>
          <p>
          This document together with the Terms and Conditions of Use for the website available at <a href="http://www.scoobylivetour.com/">www.scoobylivetour.com</a> (the “Website”) and any other policies or guidelines displayed on the Website is a contract between you (“You”) and Us concerning the collection and protection of personal information. By visiting <a href="http://www.scoobylivetour.com/">www.scoobylivetour.com</a>, You are accepting the practices described in this Privacy Policy.          
          </p>
          <p>
          Please read the following terms and conditions closely before using the Website. By using or accessing the Website, You accept all the terms and conditions set forth in this Privacy Policy. This Privacy Policy covers all personal information that We collect, use and otherwise process when You interact with the Website or with Us. All personal information will be processed in accordance with this Privacy Policy and with applicable laws, which can vary depending on your country of residence. If at any time You do not or cannot accept the conditions of this Privacy Policy, please cease accessing and using this Website.          
          </p>
          <p>
          We may change this Privacy Policy. We will notify you of any material changes to this Privacy Policy by posting a notice on the Website at <a href="http://www.scoobylivetour.com/">www.scoobylivetour.com</a> for a reasonable period of time after such changes are made and that this Privacy Policy has been updated. We will also change the “Last Modified” date at the top of this Privacy Policy. Where required by applicable law, We will obtain your consent. You should periodically review this Privacy Policy. Use of <a href="http://www.scoobylivetour.com/">www.scoobylivetour.com</a> by you following such notification and/or consent constitutes your acceptance of the Privacy Policy as modified.
          </p>
          <p>
          1.	PERSONNAL INFORMATION
          </p>
          <p>
          When used in this Privacy Policy, “Personal Information” means any information that allows to identify an individual. This may include, for example, your name, contact information, email address, information relating to an account You have created with us, including your identity and the products and services (for instance, show tickets) You have purchased or payment information. It may also include technical information, such as websites visitors’ IP addresses, browsing history on Our Websites and apps, but only when this information can identify You as an individual. Information that is aggregated and cannot be associated with an identifiable individual is not considered to be Personal Information.          
          </p>
          <p>
          2.	USE OF PERSONNAL INFORMATION
          </p>
          <p>
          YOUR PERSONAL INFORMATION MAY BE MADE AVAILABLE BY MONLOVE TO THIRD PARTIES OR SERVICE PROVIDERS TO FULFILL THE PURPOSES FOR WHICH IT HAS BEEN COLLECTED OR AS SET FORTH IN THIS PRIVACY POLICY. MONLOVE WILL NOT SELL, RENT OR TRADE YOUR PERSONAL INFORMATION TO ANY THIRD PARTY. MONLOVE GENERALLY USES PERSONAL INFORMATION FOR THE FOLLOWING PURPOSES:
          <br/>  a) to provide our products and services to our customers, to establish and administer their accounts and to respond to their inquiries;
          <br/>  b) to authenticate the identity and preserve the privacy of individuals contacting us by telephone, electronic means or otherwise;
          <br/>  c) for internal training, quality assurance, risk management, fraud detecting and safety purposes;
          <br/>  d) to meet legal and regulatory requirements;
          <br/>  e) to understand and assess the interests, wants and changing needs of customers with a view to provide personalized products and services, improve Our products and services and develop new ones;
          <br/>  f) subject to your right to withdraw consent provided in this Privacy Policy: (i) to communicate with You for the purposes of providing You with advertising and marketing messages pertaining to additional products or services that may be of interest to You; and (ii) to conduct surveys on the quality of Our products, services or customer service;
          <br/>  g) Analyze and process your application for a possible job opportunity.
          </p>
          <p>
          3.	TRACKING AND COOKIES
          </p>
          <p>
          We use cookies and similar tracking technologies to track the activity on Our Website and hold certain information. Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze Our services. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.          
          </p>
          <p>
          4.	WITHDRAWING CONSENT
          </p>
          <p>
          MONLOVE offers You the right to withdraw your Personal Information as You see fit. The right to this withdrawal extends to the rights of internet users to refuse that their Personal Information be used to certain ends.
          <br/><br/>To exercise this right, please contact MONLOVE by email at <a href="mailto:privacy@monlove.com">privacy@monlove.com</a>
          </p>
          <p>
          5.	CHILDREN
          </p>
          <p>
          We do not knowingly collect Personal Information from children under 13 years of age without the consent of a legal guardian. To purchase Our products and services, We must obtain the consent of your parent or legal guardian if You are under the age of majority and such consent is a requirement in the jurisdiction of your residence. If We learn we have collected or received Personal Information from a child where parental confirmation was required, We will delete that information. If You believe We might have any information from or about a child where We should have obtained parental consent, please contact Us by email at <a href="mailto:privacy@monlove.com">privacy@monlove.com</a>
          </p>
          <p>
          6.	THIRD PARTY LINKS ON THIS WEBSITE 
          </p>
          <p>
          This Website may contain links to third party websites. We assume no responsibility for the accuracy or pertinence of the information or links provided on these websites, and the links provided do not constitute a recommendation to purchase products or services. If You choose to access any third-party website through this Website, You do so at your own risk. During your use of third-party websites via these links, You agree to acquaint yourself with their policies. These policies may differ from this Privacy Policy and the Terms and Conditions of Use of the Website. In no event shall We be held responsible for the management, the protection and the use of Personal Information collected by third party websites.          
          </p>
          <p>
          7.	SECURITY OF PERSONAL INFORMATION
          </p>
          <p>
          We will use reasonable security measures to protect your Personal Information against unauthorized access. We have implemented security measures that contain administrative, technical and physical controls that are designed to safeguard your Personal Information.          
          </p>
          <p>
          8.	RETENTION PERIOD
          </p>
          <p>
          We will retain your Personal Information only as long as is necessary for the fulfillment of the purposes outlined in this Privacy Policy or for a longer period if required or permitted under applicable laws.<br/>
          We may retain Your Personal Information for an additional period in archive databases, including for the duration of applicable statutes of limitation in order to be able to exercise Our rights and/or defend ourselves in case of legal claims. 
          </p>
          <p>
          9.	ACCESS AND RECTIFICATION
          </p>
          <p>
          MONLOVE commits to recognizing Your right to access and rectification. If You wish to consult, modify or remove Personal Information concerning them, contact Us by email at: <a href="mailto:privacy@monlove.com">privacy@monlove.com</a>          
          </p>
          <p>
          10.	 CONTACT US
          </p>
          <p>
          If You have questions, concerns or complaints regarding this Privacy Policy, please contact Us at:
          <br/><br/>4315 Frontenac Street
          <br/>Suite 200
          <br/>Montreal, Quebec
          <br/>H2H 2M4, Canada
          <br/>Mail: <a href="mailto:privacy@monlove.com">privacy@monlove.com</a>
          <br/>Phone: +1 514 587 2992
          </p>
          <p>
          11.	 GOVERNING LAW AND JURIDICTION
          </p>
          <p>
          This Privacy Policy shall be governed by and construed by the laws of the Province of Quebec, Canada. You hereby irrevocably submit and attorn to the jurisdiction of the Courts of the district of Montreal, Province of Quebec. You agree that any definitive judgment rendered in any action, suit or proceeding is legally binding and may be executed in other jurisdictions by any means stipulated by law.          
          </p>
          <p>
          12.	 DISCLAIMER
          </p>
          <p>
          DISCLAIMER OF WARRANTIES. VISITORS ACKNOWLEDGE AND AGREE THAT THEIR USE OF THE WEBSITE IS AT THEIR SOLE RISK. MONLOVE MAKES NO GUARANTY AS TO THE ACCURACY, CORRECTNESS OR COMPLETENESS OF: (I) ANY CONTENT (INCLUDING WITHOUT LIMITATION PRODUCT DESCRIPTIONS AND/OR PRICING) APPEARING ON THE WEBSITE, AND IS NOT RESPONSIBLE FOR ANY ERRORS OR OMISSIONS ARISING FROM THE USE OF ANY CONTENT THEREOF; (II) ANY FAILURES, DELAYS OR INTERRUPTIONS WITH RESPECT TO THE ORDERING OF AND DELIVERY OF PRODUCTS ORDERED BY YOU; (III) LOSSES OR DAMAGES ARISING FROM THE USE OF CONTENT APPEARING ON THE WEBSITE; (IV) ANY CONDUCT BY VISITORS; OR (V) ANY PRODUCTS PROVIDED THROUGH OR IN CONJUNCTION WITH THE WEBSITE. ALL PRODUCTS ORDERED THROUGH THE WEBSITE ARE ACQUIRED BY YOU “AS IS” AND “WHERE IS”. EXCEPT AS EXPRESSLY PROVIDED HEREIN, MONLOVE MAKES NO REPRESENTATION OR WARRANTY OF ANY KIND TO YOU, EITHER EXPRESS OR IMPLIED. SPECIFICALLY, MONLOVE DISCLAIMS ANY IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE. LIMITATION OF LIABILITY. IN NO EVENT SHALL MONLOVE BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE WEBSITE OR ANY PRODUCTS ACQUIRED THROUGH THE WEBSITE, INCLUDING WITHOUT LIMITATION FOR LOST PROFITS OR BUSINESS, OR ANTICIPATED LOST PROFITS OR BUSINESS, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.          
          </p>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

