import { Grid, Typography } from '@material-ui/core';
import LanguageContext from 'core/context/LanguageContext';
import { Footer } from 'core/modules/Footer/Footer';
import { retrieveItemByKey } from 'core/services/ContentService';
import React, { useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import "./style.scss";
import covid_logo from '../../assets/images/covid19_text.png';
import postponed from '../../assets/images/postponed_dates.png';
import PastPerformances from '../../assets/images/PastPerformances.png';
import { Link } from 'react-router-dom';


export function Covid19Update() {
    const currentLanguage = useContext(LanguageContext)
    const [covidUpdate, setCovidUpdate] = React.useState([])

    useEffect(() => {
        document.title = retrieveItemByKey(currentLanguage, './covid19update/PageTitle')
        setCovidUpdate(retrieveItemByKey(null, 'covid19update'))
    }, [currentLanguage])


    return (
        <Grid container className="faqModule">
           

            <Grid className="faqTitle" container item>
                <Grid container item justify="center" alignItems="center" direction="row">
                    <img className="heading" src={PastPerformances} alt="" />
                </Grid>
            </Grid>
            <Grid className="ques_container" style={{ width: '100%' }}>
                <Grid className="wrap">
                </Grid>
            </Grid>

            <Grid className="ques_container" style={{ width: '100%' }}>
                <Grid  className="wrap">
                    <div className="desc">
                        <p>June 5, 2022 – Temple Theatre, Lufkin, TX</p>
                        <p>June 4, 2022 – Cowan Center, Tyler, TX</p>
                        <p>June 3, 2022 – Bartlesville Community Center, Bartlesville, OK</p>
                        <p>June 1, 2022 – Murphey Performance Hall, San Angelo, TX</p>
                        <p>April 24, 2022 – Place Bell, Laval, QC</p>
                        <p>March 27, 2022 – Genesee Theatre, WAUKEGAN, IL</p>
                        <p>March 26, 2022 – Niswonger Performing Arts Center, VAN WERT, OH</p>
                        <p>March 25, 2022 – Temple Theatre, SAGINAW, MI</p>
                        <p>March 24, 2022 – E.J. Thomas Hall - The University of Akron, AKRON, OH</p>
                        <p>March 22, 2022 – Marion Cultural & Civic Center, MARION, IL</p>
                        <p>Feb 20, 2022 – Wilkes-Barre, WILKES-BARRE, PA</p>
                        <p>Feb 18, 2022 – American Music Theatre, LANCASTER, PA</p>
                        <p>Feb 16, 2022 – The Oncenter Crouse Hinds Theatre, SYRACUSE, NY</p>
                        <p>Feb 15, 2022 – State Theatre New Jersy, NEW BRUNSWICK, NJ</p>
                        <p>Feb 12, 2022 – Honeywell Center, WABASH, IN</p>
                        <p>Feb 11, 2022 – Victory Theatre, EVANSVILLE, IN</p>
                        <p>Jan 29, 2022 – AU-RENE Theatre at the Broward Center, FT LAUDERDALE, FL</p>
                        <p>Jan 27, 2022 – Mattie Kelly Arts Center, NICEVILLE, FL</p>
                        <p>Jan 25, 2022 – Berglund Performing Arts Theatre, ROANOKE, VA</p>                        
                    </div>

                    {/* <a href="https://www.evenko.ca/en/events/32069/scooby-doo-and-the-lost-city-of-gold/place-bell/10-17-2021" target="_blank" style={{textDecoration: 'none'}}>
                        <Button variant="contained" color="secondary" className="cityDatePanelTicketButton">
                            BUY TICKETS
                        </Button>
                    </a> */}

                </Grid>
            </Grid>

            <Grid className="faqTitle" container item>
                <Grid container item justify="center" alignItems="center" direction="row">
                    <img className="heading second" src={covid_logo} alt=""/>
                </Grid>
            </Grid>
            
            <Grid className="ques_container">
                <Typography style={{textAlign: "left" }}>
                    Scooby Live Tour has been following protocols for large crowds and venues in accordance with government regulations since March 2020. We will always have your health and safety at the forefront of all our decisions.
                </Typography>
                <Typography style={{textAlign: "left" }}>
                    We are working to reschedule our upcoming performances. We will communicate new performance dates via our social media. Make sure to visit our website again soon, as we will be updating this page frequently.
                </Typography>
                <Typography style={{textAlign: "left" }}>
                    For any questions regarding tickets, please contact your point of purchase.
                </Typography>
            </Grid>
           
            <Grid container style={{paddingTop: "310px"}}>
                <Footer />  
            </Grid>          
                     
        </Grid>
    );
}