import React, { useEffect , useContext } from 'react';
import { retrieveItemByKey } from "core/services/ContentService";
import { ContactForm } from 'core/modules/ContactForm/ContactForm';
import './style.scss';
import LanguageContext from "core/context/LanguageContext";
import MetaTags from 'react-meta-tags';
import header from "../../assets/compressedimages/Sign UP_Title.png";
import { Footer } from "core/modules/Footer/Footer";

export function SignUp() {
  const currentLanguage = useContext(LanguageContext)
  useEffect(() => {
    document.title = retrieveItemByKey(currentLanguage,'/signuppages/PageTitle')
  }, [currentLanguage]);

  return (
    <div className="SignUp">
      <MetaTags>
        <meta name="SignUp" content="Sign up page"/>
      </MetaTags>
      <img src={header} alt={retrieveItemByKey(currentLanguage,'/signuppages/PageHeader')} className="headerImg"></img>
      <h1 className="hidden">{retrieveItemByKey(currentLanguage,'/signuppages/PageHeader')}</h1>
      <ContactForm/>
      <Footer />
    </div>
  );
}
