import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom'
import './style.scss';
import { Grid } from '@material-ui/core';
import { Footer } from "core/modules/Footer/Footer";
import { BuyTicketsButton } from "../../modules/BuyTicketsButton/BuyTicketsButton";
import header from "../../assets/compressedimages/vip-title.png";

export function VipPage() {

  useEffect(() => {
    document.title = "Scooby-Doo! and The Lost City of Gold - VIP";
  }, []);

  return (
    <Grid container className="vipPage">
      <Grid container className="vipText" alignContent="center">
        <Grid item xs={12} >
          <img src={header} alt="vip packages header" className="headerImg"/>
          <h1 className="hidden">VIP</h1>
        </Grid>
        <Grid container item className="vipContainer" xs={12} direction="row">
			    <Grid item className="vipPreParty">
            <h2>JINKIES! Scooby-Doo Ultimate VIP Pre-Party Pass</h2>
            <ul>
              <li>One Premium reserved ticket in the first 15 rows</li>
              <li>Exclusive access to the Scooby-Doo VIP Party</li>
              <li>Meet & Greet and photo opportunity with Scooby-Doo</li>
              <li>Healthy Scooby snack and beverage</li>
              <li>Scooby-Doo & The Mystery Machine photo booth</li>
              <li>Official Mystery Inc. member badge</li>
              <li>Crowd free merchandise shopping opportunity</li>
              <li>Scooby-Doo merchandise item – exclusive to VIP packages</li>
              <li>Limited edition VIP Laminate</li>
              <li>On Site VIP Host</li>
            </ul>
            <p>*All activities occur pre-show</p>
            <p>Program may change depending on venue and accessibility</p>
            <Link to="/tickets"><BuyTicketsButton/></Link>
          </Grid>
          <Grid item className="vipPackage">
            <h2>ZOINKS! Scooby-Doo Premium VIP Package</h2>
            <ul>
              <li>One premium reserved ticket in rows 16-25</li>
              <li>Scooby-Doo merchandise item</li>
              <li>Limited edition VIP laminate</li>
              <li>Crowd free merchandise shopping opportunity</li>
              <li>On Site VIP Host</li>
            </ul>
            <p>*All activities occur pre-show</p>
            <p>Program may change depending on venue and accessibility</p>
            <Link to="/tickets"><BuyTicketsButton/></Link>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}