import React, { useEffect, useContext } from 'react';
import { useMediaQuery } from '@material-ui/core';
import './style.scss';
import { VideoPlayer } from 'core/modules/VideoPlayer/VideoPlayer';
import { retrieveItemByKey } from 'core/services/ContentService';
import LanguageContext from "core/context/LanguageContext";
import logo from "../../assets/compressedimages/scooby_logo_highres.png";
import title1 from "../../assets/compressedimages/MAIN_TITLE1.png";
import title31L from "../../assets/compressedimages/MAIN_TITLE31L.png";
import BANNER from "../../assets/compressedimages/cadre_V2.png";
import title32L from "../../assets/compressedimages/MAIN_TITLE32L.png";
import AppDialog from 'core/modules/AppDialog/AppDialog';
import { Footer } from 'core/modules/Footer/Footer';
import { BuyTicketsButton } from 'core/modules/BuyTicketsButton/BuyTicketsButton';
import { ScheduleTourButton } from 'core/modules/ScheduleTourButton/ScheduleTourButton';
import { Link } from "react-router-dom";
// import { IKImage, IKContext } from 'imagekitio-react';
import { SignUpModule } from 'core/modules/SignUpModule/SignUpModule';
import { Grid } from '@material-ui/core';
// import { ContactForm } from 'core/modules/ContactForm/ContactForm';
import CovidPopUPForm from 'core/modules/CovidPopUPForm/CovidPopUPForm';


export function Home(props) {
  const currentLanguage = useContext(LanguageContext)
  const matchesSmallerScreen = useMediaQuery('(max-width:960px)');
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    document.title = retrieveItemByKey(currentLanguage, '/homepages/PageTitle')
  }, [currentLanguage]);

  return (
    <div className="Home">

      <h1 className="hidden">{retrieveItemByKey(currentLanguage, '/homepages/PageTitle')}</h1>
      <div className="top">
        <div className="title1">
          {/* <IKContext urlEndpoint="https://ik.imagekit.io/le9c6oinsrxj">
          <IKImage path={title1} transformation={[{
              "width": "200"
            }]} />
          </IKContext> */}
          {/* <IKContext urlEndpoint="https://ik.imagekit.io/le9c6oinsrxj">
            <IKImage path={title1} alt="Warner Bros and Monlove Present"/>
          </IKContext> */}
          <img src={title1} alt="Warner Bros and Monlove Present" />
        </div>
        <div className="LogoDiv">

          {/* <IKContext urlEndpoint="https://ik.imagekit.io/le9c6oinsrxj">
              <IKImage path={logo} alt="Scooby Doo and The List City of Gold Logo" className="logoImg"/>
            </IKContext> */}
          <img src={logo} alt="Scooby Doo and The List City of Gold Logo" className="logoImg"></img>
          <div className="schedule-btn">
            <Link to={"/tickets"}>
              <ScheduleTourButton />
            </Link>
          </div>

        </div>

        <CovidPopUPForm />

        {/* <div>
          <AppDialog />
        </div> */}
      </div>

      <div class="banner">
        <a href="https://music.apple.com/album/id/1582211549">

          <img src={BANNER} />

        </a>
      </div>


      <VideoPlayer videoUrl={retrieveItemByKey(currentLanguage, '/homepages/VideoURL')} thumbnail={retrieveItemByKey(currentLanguage, '/homepages/VideoThumbnail').url} />
      <div className="bottom">
        <div className="title3">
          {/* <IKContext urlEndpoint="https://ik.imagekit.io/le9c6oinsrxj">
            <IKImage path={matchesSmallerScreen ? title32L : title31L} alt="Scooby Doo a Brand New Original Story" />
          </IKContext> */}
          <img src={matchesSmallerScreen ? title31L : title31L} alt="Scooby Doo a Brand New Original Story" />
          <p className="hidden"> A BRAND NEW ORIGINAL STORY
            Scooby-Doo! and The Lost City of Gold, a completely original live entertainment spectacular, is the first show to combine cutting edge technology with exceptional music, puppetry, magic, singing, dance, interactive audience response video, aerial arts and video mapping.
            Audiences will join Scooby, Shaggy, Daphne, Fred, and Velma on an awe-inspiring journey
            with a brand-new story based on one of the top-grossing international franchises of all time.</p>
        </div>
        <SignUpModule />
        <Link to="/tickets">
          <BuyTicketsButton />
        </Link>
        <Grid container className="homeFooter">
          <Footer />
        </Grid>
      </div>
    </div>
  );
}
