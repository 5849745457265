import React, { useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import './style.scss';
import { retrieveItemByKey } from 'core/services/ContentService';
import { Footer } from "core/modules/Footer/Footer";
import castTitle from '../../assets/compressedimages/CAST.png';

function CastCard(props) {
  const textName = props.textName;
  // const textRole = props.textRole;
  const TextDescription = props.textDescription;
  const sourceImage = props.sourceImage;
  console.log(textName);

  return (
    <Grid container item className="cardContainer" justify="center">
      <Grid item className="imageContainer">
        <img className="castImage" alt={textName} src={sourceImage} />
      </Grid>
      <Grid container className="textContainer" direction="column" alignItems="center">
        <Grid item>
          <p className="textName">
            {textName}
          </p>
        </Grid>
        {/* <Grid item>
              <p className="textRole">
                {textRole}
              </p>
          </Grid> */}
        <Grid item>
          <p className="textDescription">
            {TextDescription}
          </p>
        </Grid>
      </Grid>
    </Grid>
  )
}

export function CastPage() {
  const [castData, setCastData] = React.useState([]);

  useEffect(() => {
    setCastData(retrieveItemByKey(null, 'creatives'))
  }, []);
 
  return (
    <Grid container className="castPage" alignItems="center" justify="center">
      <img className="castTitle" src={castTitle} alt="Cast" />

      <Grid container className="cardsContainer">
        {castData.map((castMember, index) => 
           <CastCard key={index}
           textName={castMember.Name}
           // textRole={castMember.role}
           textDescription={castMember.Text}
           sourceImage={castMember.Picture.url}
           />        
        )}
      </Grid>
      <Footer />
    </Grid>
  );
}