import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {Grid, useMediaQuery} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useContext } from 'react';
import LanguageContext from "core/context/LanguageContext";
import Moment from 'react-moment';
import 'moment/locale/fr';
import './Tickets.scss';
import { retrieveItemByKey } from 'core/services/ContentService';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ticketTitle from '../../assets/compressedimages/TICKETS_Title.png';
import ticketTitleFr from '../../assets/compressedimages/tickets-title-fr.png';
import { Footer } from "core/modules/Footer/Footer";
import { Link } from 'react-router-dom';
import scooby from "../../assets/compressedimages/scooby+temple.png";
import { VIPButton } from "core/modules/VIPButton/VIPButton";

export function Tickets() {
  const matchesMobile = useMediaQuery('(max-width:960px)');
  const [ticketData, setTicketData] = React.useState([]);
  const [expanded, setExpanded] = React.useState('false');

  const currentLanguage = useContext(LanguageContext)
  useEffect(() => {
    setTicketData(retrieveItemByKey(null, 'tickets').sort(byDate));
  }, []);

  document.title = retrieveItemByKey(currentLanguage, '/ticketpages/PageTitle')
  const handleChange = (panel,index) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    if(newExpanded && matchesMobile) {
      setTimeout(() => {
        document.getElementById(`date-${index}`).style.display = "none";
        document.getElementById(`city-${index}`).style.overflow = "visible";
      }, 500);
    }
  };

  const byDate = (a,b) => {
    return new Date(a.Start_date) - new Date(b.Start_date);
  }

  function CityDatePanel(props) {
    function handleClick(e) {
      window.open(props.entry.TicketLink);
      e.stopPropagation();
    }
    return (
      <Grid container>
        <Grid item xs={6} sm={4}>
          <Box id={'city-'+props.index} className="cityContainer" fontWeight="fontWeightBold">{props.entry.City}</Box>
          <Box className="locationContainer">{props.entry.Location}</Box>
        </Grid>
        <Grid item xs={6} sm={4} id={`date-${props.index}`} className="dateTimeContainer">
          <Box className="dateContainer"><Moment utc locale={currentLanguage === 'fr' ? "fr" : "en" } format="MMM D">{props.entry.Start_date}</Moment>
          {props.entry.End_date ? <Moment utc format=" - D, YYYY">{props.entry.End_date}</Moment> : <Moment utc format=", YYYY">{props.entry.Start_date}</Moment>}</Box>
        </Grid>
        <Grid item container xs={12} sm={12} md={4} className="buyTicketButtonContainer">
            <Button variant="contained" color="secondary" className="cityDatePanelTicketButton" onClick={handleClick}>
              {retrieveItemByKey(currentLanguage,'/ticketpages/BuyTicketButtonText')}
            </Button>
        </Grid>
      </Grid>
    );
  }

  function ShowtimeTable(props) {
    const shows = props.entry.Showtimes && props.entry.Showtimes.split("\n") || [];
    console.log("shows=>", shows);
    const showDatesTimes = []
    shows.forEach(element => {
      const [date, time] = element.split("*");
      showDatesTimes.push({ date: date, time: time })
    });
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><DateRangeIcon className="dateIcon"/><h3 className="dateIconText">Dates</h3></TableCell>
            <TableCell align="right"><AccessTimeIcon className="timeIcon" /><h3 className="timeIconText">{currentLanguage === "fr" ? "Heures" : "Times"}</h3></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {showDatesTimes.map((show, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row"><h3>{show.date}</h3></TableCell>
              <TableCell align="right"><h3>{show.time}</h3></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
  return (
    <Grid className="Tickets" container>
      <Grid container className="ticketsHeader" alignItems="center" justify="center" >
        <Grid container className="VIPButtonDiv">
          <Link to={"/VIP"}>
            <VIPButton/>
          </Link>         
        </Grid>
        <Grid item>
        <h1 className="hidden">{retrieveItemByKey(currentLanguage,'/ticketpages/PageHeader')}</h1>
        <img className="ticketTitle" src={currentLanguage === "fr" ? ticketTitleFr : ticketTitle} alt={retrieveItemByKey(currentLanguage,'/ticketpages/PageHeader')}/>
        </Grid>
        
      </Grid>
      <Grid item container justify="center" alignItems="center" className="allTickets" direction="row" xs={12}>
        <Grid className="innerTicketList" item xs={12} sm={12} md={10}>
          {ticketData && ticketData.filter(entry => entry.Language === currentLanguage).map((entry, index) => (
            <ExpansionPanel className="expansionPanelContainer" key={entry.id} square expanded={expanded === entry.id} onChange={handleChange(entry.id, index)}>
              <ExpansionPanelSummary  aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                <CityDatePanel entry={entry} index={index} />
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid className="detailContainer" justify="center" container>
                    <Grid item className="showTimeTableContainer" xs={12} sm={12}>
                      <ShowtimeTable entry={entry} />
                    </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>))}
        </Grid>
        {/* <Grid className="box">
          <p>
            NEW DATES TO BE ANNOUNCED SOON
          </p>
        </Grid> */}
        <Grid className="box">
          <p>
            For more information regarding Past Performances, please <Link to="covid19update" className="">click here.</Link>
          </p>
        </Grid>
      </Grid>
      <Footer />       
    </Grid>
  );
}
