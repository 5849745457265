import React, { useEffect , useRef } from 'react';
import { Route, Switch } from "react-router-dom";
import { Header } from "core/modules/Header/Header";
import { PageNotFound } from "../pages/PageNotFound/PageNotFound";
import "./style.scss";
import { fetchAllContent } from "core/services/ContentService";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { LanguageProvider } from 'core/context/LanguageContext';
import { ROUTES } from './routes';
import logo from '../assets/compressedimages/scooby_logo_highres.png';
import frLogo from '../assets/compressedimages/logo-fr.png';

import ReactGa from 'react-ga';

/**
 * This is the main entry point for all users.
 * It determines what page to show using react-router.
 */

export function MainEntry(props) {

  useEffect(()=>{
    ReactGa.initialize('UA-219040671-1')

    ReactGa.pageview(window.location.pathname + window.location.search)
  }, [])

  const matches = useMediaQuery('(max-width:600px)');
  const [loading, setLoadingState] = React.useState(true);
  
  const languageRef = useRef('');

  const getLocale = (location) => {
    return location.pathname.indexOf('fr/') !== -1 ? 'fr' : 'en';
  }
  
  languageRef.current = getLocale(props.history.location)

  useEffect(() => {
    // If first load, set language
    // Subsequent loads set language for next pages
    props.history.listen((location, action) => {
      languageRef.current = getLocale(location)
      window.scrollTo(0, 0);
    });

    fetchAllContent(setLoadingState);
  }, [props.history]);

  if (loading) {
    return (
      <div className="loadingContainer">
        <img className="loadingImage" src={languageRef.current === "fr" ? frLogo : logo} alt="logo"/>
        <div className="loading">
          <div className="obj"></div>
          <div className="obj"></div>
          <div className="obj"></div>
          <div className="obj"></div>
          <div className="obj"></div>
          <div className="obj"></div>
          <div className="obj"></div>
        </div>
      </div>
    )
  }
  
  const containerClass = matches ? "mobilePageContainer" : "desktopPageContainer"

    return (
      <LanguageProvider value={languageRef.current}>
          <div className="App">
            <Header />
            <div className={containerClass}>
              <Switch>
                  { ROUTES.map((route, index) => <Route key={index} exact path={route.path} component={route.component}/>) }
                  { ROUTES.map((route, index) => <Route key={index} exact path={"/fr" + route.path} component={route.component}/>) }
                  
                  { /* In case of invalid page, show this one */}
                  <Route component={PageNotFound} />
              </Switch>
            </div>

          </div>
      </LanguageProvider>
    );
}
