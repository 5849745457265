import React, { useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import './CreativesPage.scss';
import { retrieveItemByKey } from 'core/services/ContentService';
import LanguageContext from "core/context/LanguageContext";
import { Footer} from "core/modules/Footer/Footer";
import creativeTitle from '../../assets/compressedimages/CREATIVES.png';

function CastCard(props) {
  const textName = props.textName;
  const textRole = props.textRole;
  const TextDescription = props.textDescription;
  const sourceImage = props.sourceImage;

  return (
    <Grid container item className="cardContainer" justify="center">
        <Grid item className="imageContainer">
          <img className="creativeImage" alt={textName} src= {sourceImage} />
        </Grid>
        <Grid container className="textContainer" direction="column" alignItems="center">
          <Grid item>
              <p className="textName">
                {textName}
              </p>
          </Grid>
          <Grid item>
              <p className="textRole">
                {textRole}
              </p>
          </Grid>
          <Grid item>
              <p className="textDescription">
                {TextDescription}
              </p>
          </Grid>
        </Grid>
    </Grid>
  )
}

export function CreativesPage() {
  const [castData, setCastData] = React.useState([]);
  const currentLanguage = useContext(LanguageContext)

  useEffect(() => {
    document.title = retrieveItemByKey(currentLanguage,'/castpages/PageTitle')
    setCastData(retrieveItemByKey(null,'casts'))
  }, [currentLanguage]);

  return (
      <Grid container className="creativePage" alignItems="center" justify="center">
       <h1 className="hidden">{retrieveItemByKey(currentLanguage,'/castpages/PageHeader')}</h1>
          
            <img className="creativeTitle" src={creativeTitle} alt={retrieveItemByKey(currentLanguage,'/castpages/PageHeader')}/>
         
          <Grid container className="cardsContainer">
            { castData.filter(castMember => castMember.Language === currentLanguage).map((castMember,index) =>
            <CastCard key={index}
            textName={castMember.name}
            textRole={castMember.role}
            textDescription={castMember.text}
            sourceImage={castMember.picture.url}
            /> ) }
          </Grid>
          <Footer />
      </Grid>
  );
}