import React, {useContext} from 'react';
import './style.scss';
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import LanguageContext from "core/context/LanguageContext";
import title4 from "../../assets/compressedimages/MAIN_TITLE4.png";
import ReactGA from "react-ga";

export function SignUpModule(){
    const language = useContext(LanguageContext);

    const SignUpButtonClickHandler = () => {

        ReactGA.event({
          category: 'SignUpButton',
          action: 'SignUp button clicked'
        })
    }
    
    return(
        <Grid item xs={12} container className="SignUpModule"  direction="row" justify="center" alignItems="center">
            <Grid item>
                    <div className="title4">
                        <img src={title4} alt="Ready To Experience Scooby-Doo Live"/>
                    </div>
                    <Link to="/signup" className="signupLink">
                        <button className="btn" onClick={SignUpButtonClickHandler}>{language === "fr" ? "Enregistre-Moi" : "Sign Me Up!"}
                        </button>
                    </Link>
            </Grid>
        </Grid>
    );
}