import React, {useContext} from 'react';
import LanguageContext from "core/context/LanguageContext";
import ReactGA from "react-ga";

export function VIPButton() {
  const language = useContext(LanguageContext);

  const ClickHandler = () => {

    ReactGA.event({
      category: 'VIPButton',
      action: 'VIP button clicked'
    })
  }
  
  return (
    <div className="container">
        <button className="btn" onClick={ClickHandler}>{language === 'fr' ? 'ACHETER' : 'VIP'}</button>
    </div>
  );
}
