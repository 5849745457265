import AMT_Pdf from '../../core/assets/documents/AMT_PDF.pdf';
import AMT_LOGO from '../assets/images/AMT_LOGO.png';
import AMT_PIC from '../assets/images/AMT_pic.png';

import broadwayworld_Pdf from '../../core/assets/documents/Broadwayworld_PDF.pdf';
import broadwayworld_logo from '../assets/images/broadwayworld-logo.jpg';
import broadwayworld_PIC from '../assets/images/broadwayworld_PIC.jpg';

import brooklyn_vegan_Pdf from '../../core/assets/documents/brooklyn_vegan_pdf.pdf';
import brooklyn_vegan_LOGO from '../assets/images/brooklyn_vegan_logo.png';
import brooklyn_vegan_PIC from '../assets/images/brooklyn_vegan.png';

import comic_book_pdf from '../../core/assets/documents/comic_book_pdf.pdf';
import comic_book_logo from '../assets/images/comic-book-logo.png';

import cos_pdf from '../../core/assets/documents/cos_pdf.pdf';
import cos_logo from '../assets/images/cos_logo.png';
import cos_pic from '../assets/images/cos_pic.png';

import happeningnext_logo from "../assets/images/happeningnext_logo.png";
import happeningnext_pic from "../assets/images/happeningnext_pic.png";
import happeningnext_pdf from "../../core/assets/documents/happeningnext_pdf.pdf";

import hollywood_logo from "../assets/images/hollywood_logo.png"
import Hollywood_pic from "../assets/images/Hollywood_pic.png"
import hollywood_pdf from "../../core/assets/documents/hollywood_pdf.pdf";

import hollywood_soapbox_logo from "../assets/images/hollywood_soapbox_logo.png"
import hollywood_soapbox_pic from "../assets/images/hollywood_soapbox_pic.png"
import hollywood_soapbox_pdf from "../../core/assets/documents/hollywood_soapbox_pdf.pdf";

import le_journal_logo from "../assets/images/le_journal_logo.png"
import le_journal_pic from "../assets/images/le_journal_pic.png"
import le_journal_pdf from "../../core/assets/documents/le_journal_pdf.pdf";

import michigan_logo from "../assets/images/michigan_logo.png"
import michigan_pic from "../assets/images/michigan_pic.jpg"
import michigan_pdf from "../../core/assets/documents/michigan_pdf.pdf";

import nny_logo from "../assets/images/nny_logo.png"
import nny_pic from "../assets/images/nny_pic.png"
import nny_pdf from "../../core/assets/documents/nny_pdf.pdf";

import paste_logo from "../assets/images/paste_logo.png"
import paste_pic from "../assets/images/paste_pic.png"
import paste_pdf from "../../core/assets/documents/paste_pdf.pdf";

import popsugar_logo from "../assets/images/popsugar_logo.png"
import popsugar_pic from "../assets/images/popsugar_pic.png"
import popsugar_pdf from "../../core/assets/documents/popsugar_pdf.pdf";

import bulletin_logo from "../assets/images/bulletin_logo.png"
import bulletin_pic from "../assets/images/bulletin_pic.png"
import bulletin_pdf from "../../core/assets/documents/bulletin_pdf.pdf";


import variety_logo from "../assets/images/variety_logo.png"
import variety_pic from "../assets/images/variety_pic.png"
import variety_pdf from "../../core/assets/documents/variety_pdf.pdf";








export const newsArticles = [{
    pdf: AMT_Pdf,
    newsArticleLogo: AMT_LOGO,
    articletext: "Scooby-Doo! and The Lost City of Gold a completely original live entertainment spectacular.",
    ScoobyDooImage: AMT_PIC
},
{
    pdf: broadwayworld_Pdf,
    newsArticleLogo: broadwayworld_logo,
    articletext: "First Look at SCOOBY-DOO! & THE LOST CITY OF GOLD Live! ",
    ScoobyDooImage: broadwayworld_PIC
},
{
    pdf: brooklyn_vegan_Pdf,
    newsArticleLogo: brooklyn_vegan_LOGO,
    articletext: "Scooby-Doo is going on tour, shares a costume designer with Bjork",
    ScoobyDooImage: brooklyn_vegan_PIC
},
{
    pdf: comic_book_pdf,
    newsArticleLogo: comic_book_logo,
    articletext: "Scooby-Doo to Launch Spooky Live Tour",
    ScoobyDooImage: AMT_PIC
},
{
    pdf: cos_pdf,
    newsArticleLogo: cos_logo,
    articletext: "Scooby-Doo Is Going on Tour With Bjork's Costume Designer",
    ScoobyDooImage: cos_pic
},
{
    pdf: happeningnext_pdf,
    newsArticleLogo: happeningnext_logo,
    articletext: "Scooby-Doo! and The Lost City of Gold, a completely original live entertainment spectacular.",
    ScoobyDooImage: happeningnext_pic
},
{
    pdf: hollywood_pdf,
    newsArticleLogo: hollywood_logo,
    articletext: "'Scooby-Doo' Stage Show in the Works.",
    ScoobyDooImage: Hollywood_pic
},
{
    pdf: hollywood_soapbox_pdf,
    newsArticleLogo:hollywood_soapbox_logo ,
    articletext: "Scooby-Doo is back with an all-new stage adventure",
    ScoobyDooImage: hollywood_soapbox_pic
},
{
    pdf: le_journal_pdf,
    newsArticleLogo: le_journal_logo,
    articletext: "Scooby-Doo at Place Bell",
    ScoobyDooImage: le_journal_pic
},
{
    pdf: michigan_pdf,
    newsArticleLogo: michigan_logo,
    articletext: "Zoinks! A Scooby-Doo Live Show is Coming to a City Near You",
    ScoobyDooImage: michigan_pic
},
{
    pdf: nny_pdf,
    newsArticleLogo: nny_logo,
    articletext: "Scooby-Doo! and The Lost City of Gold, a completely original live entertainment spectacular.",
    ScoobyDooImage: nny_pic
},
{
    pdf: paste_pdf,
    newsArticleLogo: paste_logo,
    articletext: "Scooby-Doo! and The Gang Get New Looks from Bjork's Stylist for a New Touring Stage Show",
    ScoobyDooImage: paste_pic
},
{
    pdf: popsugar_pdf,
    newsArticleLogo: popsugar_logo,
    articletext: "Zoinks! A Scooby-Doo Live Show is Coming to a City Near You",
    ScoobyDooImage: popsugar_pic
},
{
    pdf: bulletin_pdf,
    newsArticleLogo: bulletin_logo,
    articletext: "Scooby-Doo is back with an all-new stage adventure",
    ScoobyDooImage: bulletin_pic
},
{
    pdf: variety_pdf,
    newsArticleLogo: variety_logo,
    articletext: "Scooby-Doo Live Theatre Tour Is Goofy Dane's Latest Adventure",
    ScoobyDooImage: variety_pic
}
]
