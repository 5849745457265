import React from 'react';
import './style.scss';
import ReactPlayer from 'react-player'
import { Grid } from '@material-ui/core';

export function VideoPlayer(props) {
  return (
    <Grid className="videoPlayer" container>
      <Grid item xs={12}>
        <ReactPlayer 
          url={props.videoUrl}
          playing={false}
          height="100%"
          width="100%"
          controls={false}
          loop={true}
          light={props.thumbnail}
        />
      </Grid>
    </Grid>
  );
}