import React, {useContext} from 'react';
import './style.scss';
import LanguageContext from "core/context/LanguageContext";
import ReactGA from "react-ga";

export function BuyTicketsButton() {

  const BuyTicketClickHandler = () => {

    ReactGA.event({
      category: 'BuyTicketButton',
      action: 'BuyTicket button clicked'
    })
  }

  const language = useContext(LanguageContext);
  return (
    <div className="container">
        <button className="btn" onClick={BuyTicketClickHandler}>{language === 'fr' ? 'ACHETER' : 'Buy Tickets'}</button>
    </div>
  );
}
