export function retrieveItemByKey(language, key) {
  let item = {}
  if(language != null) {
    item = sessionStorage.getItem(language + key);
  } else {
    item = sessionStorage.getItem(key);
  }
  if (item) {
    return JSON.parse(item);
  }
  return null;
}

const url = "/api/data/all";
const listDataTypes = ['tickets','newsarticles','carousels','faqs','casts','creatives','imagecarousels', 'galleryitems', 'covid19updates']

export async function fetchAllContent(setLoading) {
  let force = (window.location.search === '?force=1');
  const res = await fetch(force ? `${url}?force=1` : url);
  const body = await res.json();

  if (res.status !== 200) {
    setLoading(false);
    throw Error(body.message);
  }

  // Cache data for content entries with "keys"
  Object.keys(body)
    .filter(contentType => body[contentType] && body[contentType].length > 0)
    .forEach(contentType => {
      const content = body[contentType];
      //If content type is a list type, then we don't want to break up list objects in session storage
      if(listDataTypes.includes(contentType)) {
        console.log(content)
        sessionStorage.setItem(createCacheKey(null, contentType, null), JSON.stringify(content))
        return
      }

      //Content is not a list type, we should cache individual keys
      content.forEach(entry => {
        let keys = filterUsableKeys(Object.keys(entry))
        keys.forEach(key => {
          sessionStorage.setItem(createCacheKey(entry.Language, contentType, key), JSON.stringify(entry[key]));
        })
      })
    });

  
  setTimeout(() => {
    setLoading(false);
  }, 666);
}

function filterUsableKeys(keys) {
  return keys.filter(
    key => key !== 'createdAt' && 
           key !== 'updatedAt' && 
           key !== '__v' && 
           key !== 'id' && 
           key !== '_id' && 
           key !== 'Language'
  )
}

function createCacheKey(language, key, entryKey) {
  if (language && key && entryKey) {
    return `${language}/${key}/${entryKey}`
  } else if (language && key) {
    return `${language}/${key}`
  }
  return key;
}