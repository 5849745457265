import React, { useEffect, useContext } from 'react';
import { retrieveItemByKey } from 'core/services/ContentService';
import LanguageContext from "core/context/LanguageContext";
import 'react-image-lightbox/style.css';
import './MusicVideo.scss';
import { Footer } from "core/modules/Footer/Footer";
import { VideoPlayer } from "core/modules/VideoPlayer/VideoPlayer";
import title from "core/assets/compressedimages/MUSIC_VIDEOS.png"
import { Grid } from '@material-ui/core';
import { AppleMusicButton } from "core/modules/AppleMusicButton/AppleMusicButton";
import { SpotifyButton } from "core/modules/SpotifyButton/SpotifyButton";
import Pochette_Album_CD_REAL from "core/assets/images/Pochette_Album_CD_REAL.png";

export function MusicVideo() {
//  const currentLanguage = useContext(LanguageContext)
//   const [galleryData, setGalleryData] = React.useState([]);

//   useEffect(() => {
//     document.title = retrieveItemByKey(currentLanguage,'/gallerypages/GalleryPageTitle')
//     setGalleryData(retrieveItemByKey(null,'galleryitems'))
//   },[currentLanguage])

const currentLanguage = useContext(LanguageContext)
const [musicVideos, setMusicVideos] = React.useState([])

useEffect(() => {
    document.title = retrieveItemByKey(currentLanguage, './musicVideos/PageTitle')
    setMusicVideos(retrieveItemByKey(null, 'musicVideos'))
}, [currentLanguage])


  return (
    <div className="MusicPage">
        <div className="hidden"><h1>{retrieveItemByKey(currentLanguage,'/musicVideos/PageHeader')}</h1></div>
        <img className="title" src={title} alt={retrieveItemByKey(currentLanguage,'/musicVideos/PageHeader')}/>
        <Grid container justify="center">
            <p className="musicText">Sing along to some of Scooby-Doo! and The Lost City of Gold's groovy original songs!</p>
        </Grid>

        <div>
            <a href="https://music.apple.com/album/id/1582211549">
                <img src={Pochette_Album_CD_REAL} alt="Pochette Album CD REAL" className="albumWidth"></img>
            </a>
        </div>

        <div>
            <div className="for-newvideo">
                <VideoPlayer videoUrl='https://www.youtube.com/watch?v=kpuzfOtMXds'/>
                {/* <div class="overlay">
                    <p>Do the Scooby-Doo!</p>
                </div>  */}
            </div>
        </div>

        <div>
            <div className="for-video">
                <VideoPlayer videoUrl='https://www.youtube.com/watch?v=IQnPDtMZEBY'/>
                {/* <div class="overlay">
                    <p>Do the Scooby-Doo!</p>
                </div>  */}
            </div>
            
            <div className="for-video">
                <VideoPlayer videoUrl='https://www.youtube.com/watch?v=diZpV681BU4'/>
                {/* <div class="overlay">
                    <p>Follow the Stars</p>
                </div>  */}
            </div>
            
        </div>

        <Grid container justify="center">
            <p className="listenText">LISTEN ON</p>
        </Grid>

        <div className="listen-div">
            <div className="for-listen">
                <a href="https://open.spotify.com/album/15qgtWmQZnqJe4GjZQgzWS?si=Kf6EodMuS_O3ozXIwAEqzA" target="_blank">
                    <SpotifyButton />
                </a>               
            </div>
            
            <div className="for-listen">
                <a href="https://music.apple.com/album/id/1582211549">
                    <AppleMusicButton />  
                </a>                
            </div>           
        </div>

        
        


        
       
      <Footer />
    </div>
  );
}

