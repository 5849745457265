import React, {useEffect, useContext} from 'react';
import './FAQ.scss';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from "@material-ui/core";
import { retrieveItemByKey } from 'core/services/ContentService';
import LanguageContext from "core/context/LanguageContext";
import { Footer } from "core/modules/Footer/Footer";
import faqtitle from "../../assets/images/FAQ_Title.png";

export function Faq() {
  const currentLanguage = useContext(LanguageContext)
  const [faqData, setfaqData] = React.useState([]);

  useEffect(() => {
    document.title = retrieveItemByKey(currentLanguage,'/faqpages/PageTitle')
    setfaqData(retrieveItemByKey(null,'faqs'))
  }, [currentLanguage]);

  return (
    <Grid container className="faqModule">
      <Grid className="faqTitle" container item>
        <Grid item container justify="center" alignItems="center" direction="row">
            <h1 className="hidden">{retrieveItemByKey(currentLanguage,'/faqpages/PageHeader')}</h1>
          <img className="faqTitleImage" src={faqtitle} alt={retrieveItemByKey(currentLanguage,'/faqpages/PageHeader')} />
        </Grid>
      </Grid>
      <Grid container className="question_container" justify="center" alignItems="center" direction="row">
        <Grid item sm={10} xs={12}>
          {
            faqData
              .filter(x => x.Question)
              .filter(x => x.Language === currentLanguage)
              .map((question,index) =>
                <ExpansionPanel className="box" key={index}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="questionBox"
                  >
                    {question.Question}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    className="answerBox"
                  >
                    {question.Answer}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )
          }
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

