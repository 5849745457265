import React, { useContext } from 'react';
import './style.scss';
import { ExampleLogo } from "../ExampleLogo/ExampleLogo";
import { Link } from "react-router-dom";
import { Grid, IconButton, Menu, MenuItem, Divider } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import LanguageContext from "core/context/LanguageContext";
import { BuyTicketsButton } from "../BuyTicketsButton/BuyTicketsButton";
import { DropdownShow } from "../DropdownShow/Dropdownshow";
import { DropdownListen } from "../DropdownListen/DropdownListen";
import { HEADER_ROUTES } from 'core/router/routes';
import { HEADER_ROUTES_MOBILE } from 'core/router/routes';

export function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const matches = useMediaQuery('(max-width:940px)');
  const language = useContext(LanguageContext);
  const isHome = window.location.pathname !== "/";
  

  const handleClose = () => {
    setMenuOpen(false);
  }

  const handleClick = (event) => {
    setMenuOpen(!menuOpen);
    setAnchorEl(event.currentTarget);
  }

  const listOfMobileHeaderLinks = HEADER_ROUTES_MOBILE
    .filter(route => {
      if (language === "fr") {
        return route.frTitle;
      } else {
        return route.enTitle;
      }
    })
    .map((route, index) => {
      return  matches ? 
        <div key={index}>
         
          <MenuItem onClick={handleClick}>
            <HeaderLink style={{width: "100%", textAlign: "center"}} title={route[language + "Title"]} to={language === "fr" && route.frPath || route.path} />
          </MenuItem><Divider/></div> : <div key={index}><HeaderLink title={route[language + "Title"]} to={language === "fr" && route.frPath || route.path} /></div>
    });

  const listOfHeaderLinks = HEADER_ROUTES
    .filter(route => {
      if (language === "fr") {
        return route.frTitle;
      } else {
        return route.enTitle;
      }
    })
    .map((route, index) => {
      return  matches ? 
        <div key={index}>
          <MenuItem onClick={handleClick}>
            <HeaderLink title={route[language + "Title"]} to={language === "fr" && route.frPath || route.path} />
          </MenuItem><Divider/></div> : <div key={index}><HeaderLink title={route[language + "Title"]} to={language === "fr" && route.frPath || route.path} /></div>
    });

  const buildLinkWithLanguage = (baseLink) => {
    if (language === 'fr') {
      return '/fr' + baseLink;
    }
    return baseLink;
  }

  if (matches) {
    return (
      <>
        <div className="mobileHeader buyTicketsBar">
        <Link to={language === "fr" ? "/fr/tickets" : "/tickets"}>
          <BuyTicketsButton className="buyTicketsButton" />
        </Link>
        </div>
        <div className="burgerMenu">
                <IconButton
                    className="menuButton"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={menuOpen}
                  onClose={handleClose}
                  className="dropDownMenu"
                >
                    {listOfMobileHeaderLinks}

                  <div className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button">  
                    <a href="https://music.apple.com/ca/album/scooby-doo-and-the-lost-city-of-gold-original-cast-recording/1582211549" target="_blank">
                      Shop
                    </a>
                  </div>
          
                </Menu>
            </div>
      </>
    );
  }
  return (
    
    <Grid container className="desktopHeader" direction ="row">
      <Grid container item>
        <Grid item xs={4} className="logo" style={{zIndex: '999'}} >
          { isHome
              ? (
                <Link to="/" onClick={handleClose}>
                  <ExampleLogo/>
                </Link>
              )
              : (
                <div></div>
                )
          }
        </Grid>
        <Grid container item alignItems="center" justify="flex-end" xs className="headerLinks">          
          {/* <DropdownTicket/> */}
          <DropdownShow/>
          <Link to={"/gallery"}>
            GALLERY
          </Link>
          <DropdownListen/>
          {listOfHeaderLinks}
          <div>
            <a href="https://music.apple.com/ca/album/scooby-doo-and-the-lost-city-of-gold-original-cast-recording/1582211549" target="_blank">
              Shop
            </a>
          </div>
          <div>
            <Link to={"/VIP"}>
              VIP
            </Link>
          </div>          
          <Link to={buildLinkWithLanguage("/tickets")}>
            <BuyTicketsButton/>
          </Link>
        </Grid>
        
      </Grid>
           
    </Grid>
        
  );
}

function HeaderLink(props) {
  return (
    <Link to={props.to}>{props.title}</Link>
  )
}
