import React from 'react';
import './style.scss';
import { Grid } from "@material-ui/core";

export function ContactForm(){
    return(
        <Grid container className="contactFormContainer"  direction="column" justify="center" alignItems="center">
            <p className="topText">Sign up to be notified when tickets are on sale, receive offers, and keep up on the latest news about Scooby-Doo Live Tour.</p>
            <form action="https://monlove.us20.list-manage.com/subscribe/post?u=009e0b50fb55c7ef99503bb5f&id=2433ad2856" method="post">
                <Grid container className="textFieldContainer" direction="column" justify="center" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <input type="email" required placeholder="Email Address*" name="EMAIL"/>
                    </Grid>
                    <Grid item xs={12}>
                        <input type="text"  required placeholder="First Name*" name="FNAME"/>
                    </Grid>
                    <Grid item xs = {12}>
                        <input type="text" required placeholder="Last Name*" name="LNAME"/>
                    </Grid>
                    <Grid item xs = {12}>
                        <input type="text" required placeholder="City*" name="CITY"/>
                    </Grid>
                    <Grid item xs = {12} alignContent="flex-start">
                        <h3>How many friends will you bring along?</h3>
                    </Grid>
                    <Grid item xs = {12}>
                        <select className="dropdown" name="NUMPPL" >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5+">5+</option>
                        </select>
                    </Grid>
                    <Grid item xs={6}>
                        <button 
                            className="signUpBtnCF"
                            type="submit" 
                            >
                            Sign Me Up!
                        </button>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
}