import React, {useContext} from 'react';
import logo from 'core/assets/compressedimages/scooby-logo-big.png';
import frLogo from 'core/assets/compressedimages/logo-fr.png';
import LanguageContext from "core/context/LanguageContext";

export function ExampleLogo() {
  const currentLanguage = useContext(LanguageContext)

  return (
    <div className="ExampleLogo">
        <img src={currentLanguage === "fr" ? frLogo : logo} alt="Scooby Doo and The Lost City of Gold Header Logo" />
    </div>
  );
}
