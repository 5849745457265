import React, { useEffect, useContext } from 'react';
import { retrieveItemByKey } from 'core/services/ContentService';
import LanguageContext from "core/context/LanguageContext";
import 'react-image-lightbox/style.css';
import './style.scss';
import { Footer } from "core/modules/Footer/Footer";
import { VideoPlayer } from "core/modules/VideoPlayer/VideoPlayer";
import title from "core/assets/compressedimages/GALLERY_Title.png"
import bannerCompress from "core/assets/compressedimages/banner_compressed.png";
import img1 from "core/assets/gallery/img1.jpg";
import img2 from "core/assets/gallery/img2.jpg";
import img3 from "core/assets/gallery/img3.jpg";
import img4 from "core/assets/gallery/img4.jpg";
import img5 from "core/assets/gallery/img5.jpg";
import img6 from "core/assets/gallery/img6.jpg";
import img7 from "core/assets/gallery/img7.jpg";
import img8 from "core/assets/gallery/img8.jpg";
import img9 from "core/assets/gallery/img9.jpg";
import img10 from "core/assets/gallery/img10.jpg";
import img11 from "core/assets/gallery/img11.jpg";
import img12 from "core/assets/gallery/img12.jpg";
import img13 from "core/assets/gallery/img13.jpg";
import img14 from "core/assets/gallery/img14.jpg";
import img15 from "core/assets/gallery/img15.jpg";
import img16 from "core/assets/gallery/img16.jpg";
import img17 from "core/assets/gallery/img17.jpg";
// import img18 from "core/assets/gallery/img18.jpg";
// import img19 from "core/assets/gallery/img19.jpg";
// import img20 from "core/assets/gallery/img20.jpg";
// import img21 from "core/assets/gallery/img21.jpg";
// import img22 from "core/assets/gallery/img22.jpg";
// import img23 from "core/assets/gallery/img23.jpg";


import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export function GalleryPage() {
  const currentLanguage = useContext(LanguageContext)
  const [galleryData, setGalleryData] = React.useState([]);

  useEffect(() => {
    document.title = retrieveItemByKey(currentLanguage,'/gallerypages/GalleryPageTitle')
    setGalleryData(retrieveItemByKey(null,'galleryitems'))
  },[currentLanguage])


  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1, 
    mobilefirst: true,   
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }]
  };


  return (
    <div className="GalleryPage">
      <h1 className="header-name hidden">{retrieveItemByKey(currentLanguage,'/gallerypages/GalleryPageHeader')}</h1>
      <img className="title" src={title} alt={retrieveItemByKey(currentLanguage,'/gallerypages/GalleryPageHeader')}/>
      
     
      {/* <div className="imgContainer">
        {galleryData && galleryData.map((galleryItem, index) => 
          <img src={galleryItem.GridItemImage.url} alt={galleryItem.GridItemImageTitle} key={index}/>
        )}
      </div> */}

      <div className="gallerySlick">
        {/* <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
				<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" /> */}
        <style>{cssstyle}</style>
     
        <Slider {...settings} className="gallerySlider">
          <div className="galleryDiv">
            <img src={img5}></img>
          </div>
          <div className="galleryDiv">
            <img src={img10}></img>
          </div>
          <div className="galleryDiv">
            <img src={img3}></img>
          </div>
          <div className="galleryDiv">
            <img src={img7}></img>
          </div> 
          <div className="galleryDiv">
            <img src={img1}></img>
          </div>
          <div className="galleryDiv">
            <img src={img4}></img>
          </div>
          <div className="galleryDiv">
            <img src={img2}></img>
          </div>
          <div className="galleryDiv">
            <img src={img8}></img>
          </div> 
          <div className="galleryDiv">
            <img src={img9}></img>
          </div>        
          <div className="galleryDiv">
            <img src={img6}></img>
          </div>
          <div className="galleryDiv">
            <img src={img11}></img>
          </div>
          <div className="galleryDiv">
            <img src={img12}></img>
          </div>
          <div className="galleryDiv">
            <img src={img13}></img>
          </div> 
          <div className="galleryDiv">
            <img src={img14}></img>
          </div> 
          <div className="galleryDiv">
            <img src={img15}></img>
          </div> 
          <div className="galleryDiv">
            <img src={img16}></img>
          </div> 
         <div className="galleryDiv">
            <img src={img17}></img>
          </div> 
          {/* <div className="galleryDiv">
            <img src={img18}></img>
          </div> 
          <div className="galleryDiv">
            <img src={img19}></img>
          </div> 
          <div className="galleryDiv">
            <img src={img20}></img>
          </div> 
          <div className="galleryDiv">
            <img src={img21}></img>
          </div> 
          <div className="galleryDiv">
            <img src={img22}></img>
          </div> 
          <div className="galleryDiv">
            <img src={img23}></img>
          </div>          */}
        </Slider>
      </div>

      <VideoPlayer videoUrl={retrieveItemByKey(currentLanguage,'/gallerypages/GalleryPageVideoLink')} thumbnail={retrieveItemByKey(currentLanguage, '/gallerypages/GalleryPageVideoThumbnail').url}/>
      <img className="banner" src={bannerCompress} alt="banner"/><br />
      <Footer />
    </div>
  );
}

// const cssstyle = `

// .gallerySlick {
//     margin: 0 auto;
//     padding: 0px 0px 40px 0px;
//     width: 100%;
//     box-sizing: border-box; 
//   }
// .galleryDiv img{
//   width: 100%;
// }
// `

const cssstyle = `
.gallerySlick {
  margin: 0 auto;
  //padding: 0px 0px 40px 0px;
  width: 100%;
  box-sizing: border-box; 
}

.gallerySlider{
  overflow: hidden;
  width: 0;
  min-width: 100%;
}
.galleryDiv{
  overflow: hidden;
  position: relative;
}

.galleryDiv img{
    width: 100%;
    //height: 100vh;
    // object-fit: cover;
}
.slick-next:before, .slick-prev:before {
    color: white;
    font-size: 45px;
   
}

.gallerySlider .slick-track > div > div{
  padding: 0 10px;
}

.slick-prev{
  left: 12px;
  z-index: 1;
  width: 50px;
  height: 50px
}

.slick-next{
  right: 13px;
  z-index: 1;
  width: 50px;
  height: 50px
}
`

