import { Tickets } from "core/pages/Tickets/Tickets";
import { Home } from "core/pages/Home/Home";
import { SignUp } from "core/pages/SignUp/SignUp";
import { GalleryPage } from "core/pages/Gallery/Gallery";
import { NewsPage } from "core/pages/News/News";
import { Faq } from "core/pages/FAQPage/FAQ";
import { CastPage } from "core/pages/CastPage/CastPage";
import { CreativesPage } from "core/pages/CreativesPage/CreativesPage";
import { Legal } from "core/pages/LegalText/legaltext";
import { TermsOfUsePage } from "core/pages/TermsOfUse/TermsOfUse";
import { VipPage} from "core/pages/Vip/Vip";
import { Covid19Update } from "core/pages/Covid19Update/Covid19Update";
import { MusicVideo } from "core/pages/MusicVideo/MusicVideo"


export const ROUTES = [
    {
        enTitle: "Terms of Use",
        frTitle: "Terms of Use",
        path: "/termsofuse",
        component: TermsOfUsePage
    },
    {
        enTitle: "Privacy Policy",
        frTitle: "Privacy Policy",
        path: "/privacypolicy",
        component: Legal
    },
    {
        enTitle: "Home",
        path: "/",
        component: Home
    },
    {
        enTitle: "Tickets",
        frTitle: "Billets",
        path: "/tickets",
        frPath: "/fr/tickets",
        component: Tickets
    },
    {
        enTitle: "VIP",
        path: "/VIP",
        component: VipPage
    },
    {
        enTitle: "Gallery",
        path: "/gallery",
        component: GalleryPage
    },
    {
        enTitle: "Creatives",
        path: "/CreativesPage",
        component: CreativesPage
    },
    {
        enTitle: "Cast",
        path: "/CastPage",
        component: CastPage
    },
    {
        enTitle: "News",
        path: "/news",
        component: NewsPage
    },
    {
        enTitle: "FAQ",
        path: "/FAQ",
        component: Faq
    },
    {
        enTitle: "Sign Up",
        path: "/signup",
        component: SignUp
    },
    {
        enTitle: "FR",
        path: "/fr/tickets",
        component: Tickets
    },
    {
        enTitle: "Covid 19 Update",
        path: "/covid19update",
        component: Covid19Update
    },
    {
        enTitle: "Music Video",
        path: "/musicVideo",
        component: MusicVideo
    }
]

export const HEADER_ROUTES = [
    {
        enTitle: "FAQ",
        path: "/FAQ",
        component: Faq
    },
    {
        enTitle: "Sign Up",
        path: "/signup",
        component: SignUp
    }
  ]

  export const HEADER_ROUTES_MOBILE = [  
    {
        enTitle: "News",
        path: "/news",
        component: NewsPage
    },
    {
        enTitle: "Creatives",
        path: "/CreativesPage",
        component: CreativesPage
    },
    {
        enTitle: "Cast",
        path: "/CastPage",
        component: CastPage
    },
    {
        enTitle: "Covid 19 Update",
        path: "/covid19update",
        component: Covid19Update
    },
    {
        enTitle: "Gallery",
        path: "/gallery",
        component: GalleryPage
    },
    {
        enTitle: "FAQ",
        path: "/FAQ",
        component: Faq
    },
    {
        enTitle: "Sign Up",
        path: "/signup",
        component: SignUp
    },
    {
        enTitle: "VIP",
        path: "/VIP",
        component: VipPage
    },  
    {
        enTitle: "Music Video",
        path: "/musicVideo",
        component: MusicVideo
    }
]