import React, {useContext} from 'react';
import LanguageContext from "core/context/LanguageContext";

export function AppleMusicButton() {
  const language = useContext(LanguageContext);
  return (
    <div className="container">
        <button className="btn">{language === 'fr' ? 'ACHETER' : 'APPLE MUSIC'}</button>
    </div>
  );
}
