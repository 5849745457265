import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { MainEntry } from "core/router/MainEntry";
import {BrowserRouter} from "react-router-dom";
import { withRouter } from "react-router";

const WithRouterMainEntry = withRouter(MainEntry)
// Don't touch this file!!!!
ReactDOM.render(<BrowserRouter><WithRouterMainEntry/></BrowserRouter>, document.getElementById('root'));
