import React, { useContext } from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import LogoFacebook from "core/assets/socialicons/Facebook_Icon.png";
import LogoTwitter from "core/assets/socialicons/Twitter_Icon.png";
import LogoInstagram from "core/assets/socialicons/Instagram_Icon.png";
import LogoYoutube from "core/assets/socialicons/Youtube_Icon.png";
import LogoSpotify from "core/assets/socialicons/SPOTIFY_ICON.png";
import { ROUTES } from "core/router/routes";
import LanguageContext from "core/context/LanguageContext";
import { BuyTicketsButton } from "../BuyTicketsButton/BuyTicketsButton";
import FooterLogo from "core/assets/compressedimages/scooby_logo_highres.png";
import Monlove from "core/assets/images/MONLOVE_LOGO.png";
import Monlovelogo from "core/assets/images/WB_Logo_Shield_Flat_Pos_RGB.png";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import scooby from "../../assets/compressedimages/scooby+temple.png";
// import { IKImage, IKContext } from 'imagekitio-react';


export function Footer() {
  const matches = useMediaQuery('(min-width:950px)');
  const currentLanguage = useContext(LanguageContext);
  const filteredFooterLinks = ROUTES.filter(links => (links.enTitle !== "Home" && links.enTitle !== "Privacy Policy" && links.enTitle !== "Terms of Use"));
  const listOfFooterLinks = filteredFooterLinks
  .filter(route => {
    if (currentLanguage === "fr") {
      return route.frTitle;
    } else {
      return route.enTitle;
    }
  })
  .map((route, index) => (
    <div key={index} className="footer-link">
      <HeaderLink
        title={route[currentLanguage + "Title"]}
        to={currentLanguage === "fr" && route.frPath || route.path}
      />
    </div>


  ));
  return (
    <Grid container direction="row" className="footer">
      <Grid item container className="links footer-container" alignItems="center" justify="center">
        <Grid container item alignItems="center" justify="center" className="top-footer">
          {listOfFooterLinks}
          {matches
          ? (
            <Grid item container xs={12}>
              
              <Grid item container className="footer-container-parent-social" xs={12} alignItems="center">
                <Grid item md={4} xs={12} style={{height:"100%"}}>
                  <div className='LogoFooter'>
                    <img src={scooby} alt="Scooby Doo and Temple" className="scoobyFooter"></img>
                  </div>
                </Grid>
                <Grid item md={4} xs={12} className="footer-container-tickets">
                  <Grid item xs={12} className="mainfooter-container-image">
                    <a href="/">
                      {/* <IKContext urlEndpoint="https://ik.imagekit.io/le9c6oinsrxj">
                        <IKImage path={FooterLogo} alt="Scooby Doo and The Lost City of Gold Footer Logo" className="footer-logo"/>
                      </IKContext> */}
                      <img className="footer-logo" src={FooterLogo} alt="Scooby Doo and The Lost City of Gold Footer Logo" />
                    </a>
                  </Grid>
                  <Grid container item className="mainbottom-footer" alignItems="center" justify="center" style={{paddingBottom:'20px'}}>
                    <Grid item className="footer-info">
                      <a href="mailto:Scooby@monlove.com">Contact Us</a>
                    </Grid>
                    <Grid item className="footer-verticalpipe">
                      |
                    </Grid>
                    <Grid item className="footer-info">
                    <Link to="/privacypolicy">
                        Privacy
                      </Link>
                    </Grid>
                    <Grid item className="footer-verticalpipe">
                      |
                    </Grid>
                    <Grid item className="footer-info">
                    <Link to="/termsofuse">
                        Terms of Use
                      </Link>
                    </Grid>
                  </Grid>
              </Grid>
                <Grid container item md={4} xs={12} className="footer-container-copyright">
                    
                  <Grid item container className="footer-container-social-container" direction="column">
                    <Grid item container className="icon-style" justify="flex-end">
                      <Grid item className="social-style">
                        <a href="https://www.facebook.com/ScoobyLiveTour/">
                          <img src={LogoFacebook} alt="Scooby Doo Facebook Icon" />
                        </a>
                      </Grid>
                      <Grid item className="social-style">
                        <a href="https://twitter.com/scoobylivetour">
                        <img src={LogoTwitter} alt="Scooby Doo Twitter Icon" />
                        </a>
                      </Grid>
                      <Grid item className="social-style">
                        <a href="https://www.instagram.com/scoobylivetour/">
                        <img src={LogoInstagram} alt="Scooby Doo Instagram Icon" />
                        </a>
                      </Grid>
                      <Grid item className="social-style">
                        <a href="https://www.youtube.com/channel/UCcnAgnT8tFkD41snmvItHag">
                        <img src={LogoYoutube} alt="Scooby Doo Youtube Icon" />
                        </a>
                      </Grid>
                      <Grid item className="social-style">
                        <a href="https://open.spotify.com/album/15qgtWmQZnqJe4GjZQgzWS">
                        <img src={LogoSpotify} alt="Scooby Doo Spotify Icon" />
                        </a>
                      </Grid>
                    </Grid>
                    {/* <Grid item>
                      <p style={{textAlign:"end", paddingRight: "10px"}}>FOLLOW US ON SOCIAL @SCOOBYLIVETOUR</p>
                    </Grid> */}
                  
                </Grid>



                    <Grid container item className="footer-container-copyright-container" justify="flex-end" direction="column">
                      <Grid item>
                        <img className="monloveLogo" src={Monlovelogo} alt="Warner Bros Logo" />
                        <img className="monlove" src={Monlove} alt="Monlove Logo" />
                      </Grid>
                      <Grid item className="footer-legal-notes">
                        <p style={{fontSize: '7px'}}>
                          SCOOBY-DOO! and The Lost City of Gold ©Monlove Creations
                          Inc. / Warner Bros Consumer Products
                        </p>
                        <p style={{fontSize: '7px'}}>
                          SCOOBY-DOO and all related characters and elements © & ™
                          Hanna-Barbera. (s21)
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
              </Grid>
            
            </Grid>          
          )
          : (
            <Grid item container className="footer-container-parent-social" xs={12} alignItems="center">
                <Grid container item md={6} sm ={12} xs={12} className="footer-container-tickets" justify="center">
              {/* <Link to="/tickets">
                <BuyTicketsButton className="buyTicketsButton" />
              </Link> */}
                   <Grid item xs={12} className="mainfooter-container-image">
                    <a href="/">
                      {/* <IKContext urlEndpoint="https://ik.imagekit.io/le9c6oinsrxj">
                        <IKImage path={FooterLogo} alt="Scooby Doo and The Lost City of Gold Footer Logo" className="footer-logo"/>
                      </IKContext> */}
                      <img className="footer-logo" src={FooterLogo} alt="Scooby Doo and The Lost City of Gold Footer Logo" />
                    </a>
                  </Grid>
                <Grid container item className="mainbottom-footer" alignItems="center" justify="center">
                  <Grid item className="footer-info">
                    <a href="mailto:Scooby@monlove.com">Contact Us</a>
                  </Grid>
                  <Grid item className="footer-verticalpipe">
                    |
                  </Grid>
                  <Grid item className="footer-info">
                  <Link to="/privacypolicy">
                      Privacy
                    </Link>
                  </Grid>
                  <Grid item className="footer-verticalpipe">
                    |
                  </Grid>
                  <Grid item className="footer-info">
                  <Link to="/termsofuse">
                      Terms of Use
                    </Link>
                  </Grid>
                </Grid>
            </Grid>
            <Grid className="footer-container-social" item container direction="column" sm={12} md={3} xs={12}>
              <Grid item container className="footer-container-social-container-mobile" direction="row" justify="center">
                <Grid item container className="icon-style" justify="center">
                  <Grid item className="social-style">
                    <a href="https://www.facebook.com/ScoobyLiveTour/">
                      {/* <IKContext urlEndpoint="https://ik.imagekit.io/le9c6oinsrxj">
                        <IKImage path={LogoFacebook} alt="Scooby Doo Facebook Icon"/>
                      </IKContext> */}
                      <img src={LogoFacebook} alt="Scooby Doo Facebook Icon" />
                    </a>
                  </Grid>
                  <Grid item className="social-style">
                    <a href="https://twitter.com/scoobylivetour">
                      {/* <IKContext urlEndpoint="https://ik.imagekit.io/le9c6oinsrxj">
                        <IKImage path={LogoTwitter} alt="Scooby Doo Twitter Icon"/>
                      </IKContext> */}
                      <img src={LogoTwitter} alt="Scooby Doo Twitter Icon" />
                    </a>
                  </Grid>
                  <Grid item className="social-style">
                    <a href="https://www.instagram.com/scoobylivetour/">
                      {/* <IKContext urlEndpoint="https://ik.imagekit.io/le9c6oinsrxj">
                        <IKImage path={LogoInstagram} alt="Scooby Doo Instagram Icon"/>
                      </IKContext> */}
                      <img src={LogoInstagram} alt="Scooby Doo Instagram Icon" />
                    </a>
                  </Grid>
                  <Grid item className="social-style">
                    <a href="https://www.youtube.com/channel/UCcnAgnT8tFkD41snmvItHag">
                    {/* <IKContext urlEndpoint="https://ik.imagekit.io/le9c6oinsrxj">
                      <IKImage path={LogoYoutube} alt="Scooby Doo Youtube Icon"/>
                    </IKContext> */}
                    <img src={LogoYoutube} alt="Scooby Doo Youtube Icon" />
                    </a>
                  </Grid>
                  <Grid item className="social-style">
                      <a href="https://open.spotify.com/album/15qgtWmQZnqJe4GjZQgzWS">
                        <img src={LogoSpotify} alt="Scooby Doo Spotify Icon" />
                      </a>
                  </Grid>
                </Grid>
                <Grid item>
                  <p>FOLLOW US ON SOCIAL @Scoobylivetour</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item sm={12} md={3} xs={12} className="footer-container-copyright">
              <Grid container item className="footer-container-copyright-container" justify="center" direction="column" style={{paddingRight: "0"}}>
                <Grid item>
                  <img className="monloveLogo" src={Monlovelogo} alt="Warner Bros Logo" />
                  <img className="monlove" src={Monlove} alt="Monlove Logo" />
                </Grid>
                <Grid item className="footer-legal-notes">
                  <p style={{fontSize: '7px'}}>
                    SCOOBY-DOO! and The Lost City of Gold ©Monlove Creations
                    Inc. / Warner Bros Consumer Products
                  </p>
                  <p style={{fontSize: '7px'}}>
                    SCOOBY-DOO and all related characters and elements © & ™
                    Hanna-Barbera. (s21)
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          )
          }

        </Grid>
      </Grid>
    </Grid>
  );
}

function HeaderLink(props) {
  return <Link to={props.to}>{props.title}</Link>;
}
